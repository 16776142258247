import { Component, HostListener, Inject } from '@angular/core';
import { DOCUMENT,Location } from '@angular/common';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent 
{
  currentPage: any = '';

  constructor(@Inject(DOCUMENT) private document: Document,private location : Location ) 
  {
     this.currentPage = this.location.path();
  }
  
  showWines: any = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 20 ||     
    document.documentElement.scrollTop > 20) {
      document.getElementById('navigation-container').classList.add('grey');
    } else {
      document.getElementById('navigation-container').classList.remove('grey');
    }
  }
  name = 'Winehunter';

  ngOnInit() {

  }

}
