import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { WinesComponent } from '../wines/wines.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { TermsComponent } from '../terms/terms.component';
import { ContactComponent } from '../contact/contact.component';
const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'wines',
        component: WinesComponent,
    },
    {
        path: 'privacy',
        component: PrivacyComponent,
    },
    {
        path: 'contact',
        component: ContactComponent,
    },
    {
        path: 'terms',
        component: TermsComponent,
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule { }