import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';


declare var jQuery:  any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit {

  wines: any = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http.get('https://api.winehunter.co.za/api/wine/featured').subscribe(responseData => {
      this.wines  =  responseData;
    });
  }
}
