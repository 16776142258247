<div id="image-slider" style="background-image:url('/assets/images/home/banner1.jpg')">
    <div class="overlay"></div>
</div>
<div class="contained">
    <div class="services">
        <div class="service-block">
            <div class="service-block-image" style="background-image:url('assets/images/home/sourcing.jpg')"></div>
            <h3>Sourcing</h3>
            <p>Wines we distribute is sourced from all over the Western Cape, all wines are tasted and evaluated and only distributed if it can provide good value for money provided the reseller marks up as modestly as we do</p>
            <!-- <a href="#Read-more">Read more about sourcing</a>  -->
        </div>
        <div class="service-block">
            <div class="service-block-image" style="background-image:url('assets/images/home/wh2.jpg')"></div>
            <h3>Availability</h3>
            <p>Although we do depend on sourcing overruns, small bulk less than 1000L at a time, 30 cases or less such sources are not always available, we do happen to also source regular supply of decent wines being it labeled or unlabeled </p>
            <!-- <a href="#Read-more">Read more about availablility</a>  -->
        </div>
        <div class="service-block">
            <div class="service-block-image" style="background-image:url('assets/images/home/target market.jpg')"></div>
            <h3>Market</h3>
            <p>We aim to supply the lesser explored markets outside of the Western Cape with these little gems we locate here in Western Cape - stocks and supply may vary but is always vetted by us - we are not exporters nor are we bulk suppliers</p>
            <!-- <a href="#Read-more">Read more about our marketing model </a>  -->
        </div>
    </div>
</div>  
<!-- <div class="wine-list">
    <h2>Wines</h2>
    <h4>Currently available - prices exclude delivery</h4>
        <div class="nav previous"><i class="fas fa-chevron-left"></i></div>
            <div class="wine-slider">
                <div class="wine-box" *ngFor="let wine of wines">
                    <img src="data:image/png;base64,{{wine.pic}}" title={{wine.name}}>
                    <div class="description">
                        <h2>{{wine.name}}</h2>
                        <p>{{wine.description}}</p>
                    </div>
                </div>
            </div>
        <div class="nav next"><span><i class="fas fa-chevron-right"></i></span></div>
    </div>    -->
<!-- <div class="testimonials">
    <h2>Testimonials</h2>
    <div class="testimonial-box">
            <span class="testimonial-date">January 5, 2019</span>
            <h5>Show Us Your Corks: 2018 California Wine Month Cork Photo Contest</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis odit veritatis incidunt esse quisquam aperiam quas voluptatem repudiandae. Omnis magni adipisci facilis quo fugiat voluptatem repudiandae illo libero vel odit.</p>
    </div>
    <div class="testimonial-box">
            <span class="testimonial-date">January 5, 2019</span>
            <h5>Show Us Your Corks: 2018 California Wine Month Cork Photo Contest</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis odit veritatis incidunt esse quisquam aperiam quas voluptatem repudiandae. Omnis magni adipisci facilis quo fugiat voluptatem repudiandae illo libero vel odit.</p>
    </div>
    <div class="testimonial-box">
            <span class="testimonial-date">January 5, 2019</span>
            <h5>Show Us Your Corks: 2018 California Wine Month Cork Photo Contest</h5>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis odit veritatis incidunt esse quisquam aperiam quas voluptatem repudiandae. Omnis magni adipisci facilis quo fugiat voluptatem repudiandae illo libero vel odit.</p>
    </div>
</div> -->