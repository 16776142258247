<div class="legal" *ngIf="!age && !exception">
    <div class="logo">
        <img src="/assets/images/whnbg.png" alt="logo">
    </div>
    <h3 class="">ARE YOU OF LEGAL DRINKING AGE IN YOUR COUNTRY?</h3>
    <div class="general">
        <p>By entering, you accept our Privacy and Cookie Notice and Conditions of Use</p>
    </div>
    <div class="links">
        <a href="/privacy" target="_blank">Privacy Policy</a>
        <a href="/terms" target="_blank">Condition of Use</a>
    </div>
    <button class="accept" (click)="accept()">YES, I AM IN LEGAL DRINKING AGE</button>
</div>
<!-- Accepted the Age Agreement. -->
<div *ngIf="age && !exception">
    <app-navigation></app-navigation>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
<!-- These pages can be viewed without accepting the age agreement. -->
<div *ngIf="exception">
    <router-outlet></router-outlet>
</div>