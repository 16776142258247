import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-wines',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  contact: any = {
		name: "",
		number: "",
		email: "",
		message: ""
  };
  

  constructor(private http: HttpClient, private formBuilder: FormsModule) 
  {
    
  }
    
    ngOnInit(): void {

      console.log(this.contact);
    }

    postMessage() {  
      console.log(this.contact);
      //this.http.post('https://api.winehunter.co.za/api/contact/viaForm', data);
    }
}
