<div id="navigation-container" [ngClass]="{'home': currentPage == ''}">
	<div class="contained p-10" id="title-bar">
		<div class="inline-block">
			<div id="phone-container" class="contact inline-block">
				<i class="fas fa-mobile-alt"></i>
				<a id="phone" class="no-decoration" href="tel:0658595659"> (+27)65 859 5659</a>
			</div>
			<div class="contact inline-block p-l-10">
				<i class="fas fa-envelope"></i>
				<a id="email" class="no-decoration" href="mailto:info@winehunter.co.za" > info@winehunter.co.za</a>
			</div>
		</div>
		<div class="right">
			<span id="lang">
				EN
			</span>
			<span id="brands">
				 <a href="https://www.facebook.com/Winehunter-109704387227011/" target="_blank"><i class="fab fa-facebook-f"></i></a>
			</span>
		</div>
	</div>
	<div class="contained" id="main-nav">
		<ul class="p-0 m-0">
			<!-- <li class="tl">
				<a routerLink="/" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active" href="/">Home</a>
			</li> -->
			<!-- <li class="tl"> -->
				<!-- <a (click) = "showWines = true"  href="#">Wines</a> -->
				<!-- <a  routerLink="/wines" routerLinkActive="active" href="wines">Wines</a> -->
			<!-- </li> -->
			<!-- <li class="tl">
				<a href="feedback">Feedback</a>
			</li> -->
			<li id="logo-container" class="tc">
				<a href="/">
					<img class="logo" width='100' src="assets/images/whnbg.png" />
				</a>
			</li>
			<!-- <li class="tr">
				<a href="about">About</a>
			</li> -->
			<li class="tr">
				<a href="contact">Contact</a>
			</li>
			<!-- <li class="tr">
				<a href="login">Login</a>
			</li> -->
		</ul>
	</div>
</div>
