<div class="contained main">
	<div class="contact">
		<form name="contactForm" (submit)="postMessage()">
			<div class="form-group">
				<div class="contact-container">
					<div class="contact-block">
						<h2>Contact Info</h2>
						<label>Call Us: <a href="tel:0658595659">+(27)65 859 5659</a></label>
						<br>
						<label>Email: <a target="_blank" href="info@winehunter.co.za">info@winehunter.co.za</a></label>
						<h2>Our Address</h2>
						<p>Unit 10 Hangar 17</p>
						<p>Eight 17th Avenue</p>
						<p>Maitland</p>
						<p>7404</p>
					</div>
					<!-- <div class="message-block">
						<h2>Drop us a line</h2>
						<div class="form-group">
							<input type="text" placeholder="Name" class="form-control" name="name" [(ngModel)]="contact.name" required pattern="^[a-zA-Z]+$">
						</div>
						<div class="form-group">
							<input type="number" placeholder="Number" class="form-control" name="number" [(ngModel)]="contact.number" required>
						</div>
						<div class="form-group">
							<input type="text" placeholder="Email" class="form-control" name="contactEmail" [(ngModel)]="contact.email" required pattern="^[a-zA-Z]+$">
						</div>
						<div class="form-group">
							<textarea type="text" placeholder="Message" class="form-control" name="contactMessage" [(ngModel)]="contact.message" required></textarea>
						</div>
						<button class="btn btn-primary btn-sm">Add</button>
					</div> -->
				</div>
			</div>
		</form>
	</div>
</div>