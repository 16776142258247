import { Component } from '@angular/core';
import {Location} from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'winehunter';
  age: any = false;
  exceptions: any = [
    '/privacy',
    '/terms'
  ];
  exception: any = false;
  
  constructor(private location: Location) 
  {
    let currentPath = this.location.path();
    if (this.exceptions.indexOf(currentPath) !== -1) {
      this.exception = true;
    } else {
      this.exception = false;
      this.age = localStorage.getItem("age"); 
    }
    
  }

  accept()
  {
    localStorage.setItem("age", "true");
    location.reload();
  }
}
