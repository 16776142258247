<footer>
    <div class="contained footer">
        <div class="footer-block">
            FOLLOW US
            <div id="social-icons">
                <a  href="https://www.facebook.com/Winehunter-109704387227011/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                <p id ="copyright">Copyright © 2020 - Winehunter</p>
            </div>
        </div>
        <div class="footer-block">
            
        </div>
        <div class="footer-block">
            
        </div>
    </div>
</footer>